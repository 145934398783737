import React, { useEffect, useMemo, useState } from 'react';
import Image from 'next/future/image';
import cn from 'classnames';
import { CtaButton } from '@/components/Cta/Cta';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { AUTH_STEPS, PASSWORD_RESET_OTP_LENGTH } from '../../constants';
import { StepProps } from '../types';
import openEyeIcon from '@/public/icons/account/eye_open.svg';
import closedEyeIcon from '@/public/icons/account/eye_closed.svg';

import { validatePassword } from '../../helpers';
import CognitoPassowrdReq from '../../CognitoPasswordReq';

const ResetPasswordStep = ({
  setStep,
  data,
  setData,
  onSuccess,
}: StepProps) => {
  const { cognitoResetPassword, cognitoResendOtp, loading } =
    useCustomerContext();
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmedNewPassword, setConfirmedNewPassword] = useState('');

  useEffect(() => {
    setData({ ...data, password: '', newPassword: '', otp: '' });
  }, []);

  const onSignInClick = async () => {
    if (data.newPassword !== confirmedNewPassword) {
      setError('Passwords do not match.');

      return;
    }

    try {
      const auth = await cognitoResetPassword(
        data.email,
        data.newPassword,
        data.otp,
      );

      if (!auth?.error) {
        if (onSuccess) onSuccess();
        else setStep(AUTH_STEPS.PASSWORD);
      } else {
        setError(auth?.message ?? 'Invalid or expired OTP. Request a new one.');
      }
    } catch (e) {
      setError('Something went wrong while verifying the OTP.');
    }
  };

  const onResendClick = async () => {
    try {
      const customer = await cognitoResendOtp(data.email);

      if (!customer.error) setStep(AUTH_STEPS.OTP_VERIFICATION);
      else setError('Something went wrong while resending the OTP.');
    } catch (e) {
      setError('Something went wrong while resending the OTP.');
    }
  };

  const validForm = useMemo(
    () =>
      data.otp.length === PASSWORD_RESET_OTP_LENGTH &&
      validatePassword(data.newPassword) &&
      confirmedNewPassword.length > 0,
    [data.newPassword, data.otp, confirmedNewPassword],
  );

  return (
    <React.Fragment>
      <div className="flex flex-wrap items-center justify-between gap-x-2">
        <div>
          Sent to {data.email}{' '}
          <button
            onClick={() => setStep(AUTH_STEPS.FORGOT_PASSWORD)}
            className="underline underline-offset-1"
          >
            Change
          </button>
        </div>
        <button
          className="text-[#0D6EFD] underline-offset-1 hover:underline"
          onClick={onResendClick}
        >
          Resend
        </button>
      </div>
      <input
        type="text"
        placeholder="123456"
        className={cn(
          'mt-2.5 h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50',
        )}
        value={data.otp}
        onChange={(e) => setData({ ...data, otp: e.target.value })}
        autoComplete="otp"
      />
      <h2 className="mt-4 text-xl font-bold">Enter a new password</h2>
      <div className="relative">
        <input
          className="mt-2 h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50"
          placeholder="Enter a new password..."
          type={showPassword ? 'text' : 'password'}
          value={data.newPassword}
          onChange={(e) => setData({ ...data, newPassword: e.target.value })}
          autoComplete="new-password"
        />
        <button
          className="absolute right-3 top-1/2 mt-1 h-5 w-5 -translate-y-1/2"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          <Image
            src={showPassword ? openEyeIcon : closedEyeIcon}
            alt="Toggle Password"
            height={50}
            width={50}
            className="h-full w-full"
          />
        </button>
      </div>
      <input
        className="mt-2 h-12 w-full rounded border border-gray-700/10 px-3 text-gray-700 outline-1 outline-gray-700 placeholder:text-gray-700/50"
        placeholder="Re-enter new password..."
        type="password"
        value={confirmedNewPassword}
        onChange={(e) => setConfirmedNewPassword(e.target.value)}
        autoComplete="new-password"
      />
      <CtaButton
        type="button"
        className="mt-2.5 !h-12 w-full !rounded !text-sm"
        onClick={onSignInClick}
        loading={loading}
        disabled={!validForm}
      >
        Reset Password
      </CtaButton>
      {error ? <div className="text-sm text-red">{error}</div> : null}
      <CognitoPassowrdReq password={data.newPassword} />
    </React.Fragment>
  );
};

export default ResetPasswordStep;
