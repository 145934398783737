import Image from 'next/future/image';
import { PASSWORD_REQUIREMENTS } from './constants';

import redCheck from '@/public/icons/account/check-circle-red.svg';
import greenCheck from '@/public/icons/account/check-circle-green.svg';

const CognitoPassowrdReq = ({ password }: { password: string }) => (
  <div className="mt-2">
    Your password must contain
    <ul className="mt-1 space-y-0.5">
      {PASSWORD_REQUIREMENTS.map((requirement) => {
        const isValid = requirement.regex.test(password);

        return (
          <li key={requirement.title} className="flex items-center gap-x-1">
            <div className="h-5 w-5">
              <Image
                src={isValid ? greenCheck : redCheck}
                alt="Check"
                height={50}
                width={50}
                className="h-full w-full"
              />
            </div>
            <span>{requirement.title}</span>
          </li>
        );
      })}
    </ul>
  </div>
);

export default CognitoPassowrdReq;
